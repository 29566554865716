import { SyntheticEvent } from 'react'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { Color } from 'types'
import { changeColor } from 'store/palette/slice'

import styles from './styles.module.scss'


type Props = {
  shades: Color[] | undefined,
  activeColor: string,
  index: number,
  isOpen: boolean,
}

export const Shades = ({ shades, activeColor, index, isOpen }: Props) => {
  const dispatch = useDispatch()

  const handleClick = (color: string) => (e: SyntheticEvent) => {
    e.stopPropagation()
    dispatch(changeColor({ index, color }))
  }

  if (!shades?.length) return null

  return (
    <div className={classNames(styles.shades, isOpen && styles.open)}>
      {shades.map(({ hex, textColor }) => (
        <div
          key={hex}
          className={classNames(styles.shade, activeColor === hex && styles.active)}
          style={{ backgroundColor: hex, color: textColor }}
          onClick={handleClick(hex)}
        >
          <span>{hex}</span>
        </div>
      ))}
    </div>
  )
}

import { useDispatch } from 'react-redux'

import { changeColors } from 'store/palette/slice'

import styles from './styles.module.scss'


const Footer = () => {
  const dispatch = useDispatch()

  const handleChangeColors = () => {
    dispatch(changeColors())
  }

  return (
    <div className={styles.footer}>
      <div className={styles.button} onClick={handleChangeColors}>Новая палитра</div>
    </div>
  )
}

export default Footer

export const MODAL_TYPES = {
  VIEW: 'VIEW',
  CSS: 'CSS',
  GET_PALETTE_FROM_IMAGE: 'GET_PALETTE_FROM_IMAGE',
  UNDEFINED: '',
} as const

export const MOBILE_WIDTH = 768 as const



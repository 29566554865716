import { Ral } from 'types'


export const RALS: Ral[] = [
  { hex: '#BEBD7F', ral: 1000, name: 'Green Beige' },
  { hex: '#C2B078', ral: 1001, name: 'Beige' },
  { hex: '#C6A664', ral: 1002, name: 'Sand Yellow' },
  { hex: '#E5BE01', ral: 1003, name: 'Signal Yellow' },
  { hex: '#CDA434', ral: 1004, name: 'Golden Yellow' },
  { hex: '#A98307', ral: 1005, name: 'Honey Yellow' },
  { hex: '#E4A010', ral: 1006, name: 'Maize Yellow' },
  { hex: '#DC9D00', ral: 1007, name: 'Daffodil Yellow' },
  { hex: '#8A6642', ral: 1011, name: 'Brown Beige' },
  { hex: '#C7B446', ral: 1012, name: 'Lemon Yellow' },
  { hex: '#EAE6CA', ral: 1013, name: 'Oyster White' },
  { hex: '#E1CC4F', ral: 1014, name: 'Ivory' },
  { hex: '#E6D690', ral: 1015, name: 'Light Ivory' },
  { hex: '#EDFF21', ral: 1016, name: 'Sulfur Yellow' },
  { hex: '#F5D033', ral: 1017, name: 'Saffron Yellow' },
  { hex: '#F8F32B', ral: 1018, name: 'Zinc Yellow' },
  { hex: '#9E9764', ral: 1019, name: 'Grey Beige' },
  { hex: '#999950', ral: 1020, name: 'Olive Yellow' },
  { hex: '#F3DA0B', ral: 1021, name: 'Rape Yellow' },
  { hex: '#FAD201', ral: 1023, name: 'Traffic Yellow' },
  { hex: '#AEA04B', ral: 1024, name: 'Ochre Yellow' },
  { hex: '#FFFF00', ral: 1026, name: 'Luminous Yellow' },
  { hex: '#9D9101', ral: 1027, name: 'Curry' },
  { hex: '#F4A900', ral: 1028, name: 'Melon Yellow' },
  { hex: '#D6AE01', ral: 1032, name: 'Broom Yellow' },
  { hex: '#F3A505', ral: 1033, name: 'Dahlia Yellow' },
  { hex: '#EFA94A', ral: 1034, name: 'Pastel Yellow' },
  { hex: '#6A5D4D', ral: 1035, name: 'Pearl Beige(Metalic Color)' },
  { hex: '#705335', ral: 1036, name: 'Pearl Gold(Metalic Color)' },
  { hex: '#F39F18', ral: 1037, name: 'Sun Yellow' },
  { hex: '#ED760E', ral: 2000, name: 'Yellow Orange' },
  { hex: '#C93C20', ral: 2001, name: 'Red Orange' },
  { hex: '#CB2821', ral: 2002, name: 'Vermilion' },
  { hex: '#FF7514', ral: 2003, name: 'Pastel Orange' },
  { hex: '#F44611', ral: 2004, name: 'Pure Orange' },
  { hex: '#FF2301', ral: 2005, name: 'Luminous Orange' },
  { hex: '#FFA420', ral: 2007, name: 'Luminous Bright Orange' },
  { hex: '#F75E25', ral: 2008, name: 'Bright Red Orange' },
  { hex: '#F54021', ral: 2009, name: 'Traffic Orange' },
  { hex: '#D84B20', ral: 2010, name: 'Signal Orange' },
  { hex: '#EC7C26', ral: 2011, name: 'Deep Orange' },
  { hex: '#E55137', ral: 2012, name: 'Salmon Orange' },
  { hex: '#C35831', ral: 2013, name: 'Pearl Orange(Metalic Color)' },
  { hex: '#AF2B1E', ral: 3000, name: 'Flame Red' },
  { hex: '#A52019', ral: 3001, name: 'Signal Red' },
  { hex: '#A2231D', ral: 3002, name: 'Carmine Red' },
  { hex: '#9B111E', ral: 3003, name: 'Ruby Red' },
  { hex: '#75151E', ral: 3004, name: 'Purple Red' },
  { hex: '#5E2129', ral: 3005, name: 'Wine Red' },
  { hex: '#412227', ral: 3007, name: 'Black Red' },
  { hex: '#642424', ral: 3009, name: 'Oxide Red' },
  { hex: '#781F19', ral: 3011, name: 'Brown Red' },
  { hex: '#C1876B', ral: 3012, name: 'Beige Red' },
  { hex: '#A12312', ral: 3013, name: 'Tomato Red' },
  { hex: '#D36E70', ral: 3014, name: 'Antique Pink' },
  { hex: '#EA899A', ral: 3015, name: 'Light Pink' },
  { hex: '#B32821', ral: 3016, name: 'Coral Red' },
  { hex: '#E63244', ral: 3017, name: 'Rose' },
  { hex: '#D53032', ral: 3018, name: 'Strawberry Red' },
  { hex: '#CC0605', ral: 3020, name: 'Traffic Red' },
  { hex: '#D95030', ral: 3022, name: 'Salmon Pink' },
  { hex: '#F80000', ral: 3024, name: 'Luminous Red' },
  { hex: '#FE0000', ral: 3026, name: 'Luminous Bright Red' },
  { hex: '#C51D34', ral: 3027, name: 'Raspberry Red' },
  { hex: '#CB3234', ral: 3028, name: 'Pure Red' },
  { hex: '#B32428', ral: 3031, name: 'Orient Red' },
  { hex: '#721422', ral: 3032, name: 'Pearl Ruby Red(Metalic Color)' },
  { hex: '#B44C43', ral: 3033, name: 'Pearl Pink(Metalic Color)' },
  { hex: '#6D3F5B', ral: 4001, name: 'Red Lilac' },
  { hex: '#922B3E', ral: 4002, name: 'Red Violet' },
  { hex: '#DE4C8A', ral: 4003, name: 'Heather Violet' },
  { hex: '#641C34', ral: 4004, name: 'Claret Violet' },
  { hex: '#6C4675', ral: 4005, name: 'Blue Lilac' },
  { hex: '#A03472', ral: 4006, name: 'Traffic Purple' },
  { hex: '#4A192C', ral: 4007, name: 'Purple Violet' },
  { hex: '#924E7D', ral: 4008, name: 'Signal Violet' },
  { hex: '#A18594', ral: 4009, name: 'Pastel Violet' },
  { hex: '#CF3476', ral: 4010, name: 'Telemagenta' },
  { hex: '#8673A1', ral: 4011, name: 'Pearl Violet(Metalic Color)' },
  { hex: '#6C6874', ral: 4012, name: 'Pearl Black Berry(Metalic Color)' },
  { hex: '#354D73', ral: 5000, name: 'Violet Blue' },
  { hex: '#1F3438', ral: 5001, name: 'Green Blue' },
  { hex: '#20214F', ral: 5002, name: 'Ultramarine Blue' },
  { hex: '#1D1E33', ral: 5003, name: 'Saphire Blue' },
  { hex: '#18171C', ral: 5004, name: 'Black Blue' },
  { hex: '#1E2460', ral: 5005, name: 'Signal Blue' },
  { hex: '#3E5F8A', ral: 5007, name: 'Brillant Blue' },
  { hex: '#26252D', ral: 5008, name: 'Grey Blue' },
  { hex: '#025669', ral: 5009, name: 'Azure Blue' },
  { hex: '#0E294B', ral: 5010, name: 'Gentian Blue' },
  { hex: '#231A24', ral: 5011, name: 'Steel Blue' },
  { hex: '#3B83BD', ral: 5012, name: 'Light Blue' },
  { hex: '#1E213D', ral: 5013, name: 'Cobalt Blue' },
  { hex: '#606E8C', ral: 5014, name: 'Pigeon Blue' },
  { hex: '#2271B3', ral: 5015, name: 'Sky Blue' },
  { hex: '#063971', ral: 5017, name: 'Traffic Blue' },
  { hex: '#3F888F', ral: 5018, name: 'Turquoise Blue' },
  { hex: '#1B5583', ral: 5019, name: 'Capri Blue' },
  { hex: '#1D334A', ral: 5020, name: 'Ocean Blue' },
  { hex: '#256D7B', ral: 5021, name: 'Water Blue' },
  { hex: '#252850', ral: 5022, name: 'Night Blue' },
  { hex: '#49678D', ral: 5023, name: 'Distant Blue' },
  { hex: '#5D9B9B', ral: 5024, name: 'Pastel Blue' },
  { hex: '#2A6478', ral: 5025, name: 'Pearl Gentian Blue(Metalic Color)' },
  { hex: '#102C54', ral: 5026, name: 'Pearl Night Blue(Metalic Color)' },
  { hex: '#316650', ral: 6000, name: 'Patina Green' },
  { hex: '#287233', ral: 6001, name: 'Emerald Green' },
  { hex: '#2D572C', ral: 6002, name: 'Leaf Green' },
  { hex: '#424632', ral: 6003, name: 'Olive Green' },
  { hex: '#1F3A3D', ral: 6004, name: 'Blue Green' },
  { hex: '#2F4538', ral: 6005, name: 'Moss Green' },
  { hex: '#3E3B32', ral: 6006, name: 'Grey Olive' },
  { hex: '#343B29', ral: 6007, name: 'Bottle Green' },
  { hex: '#39352A', ral: 6008, name: 'Brown Green' },
  { hex: '#31372B', ral: 6009, name: 'Fir Green' },
  { hex: '#35682D', ral: 6010, name: 'Grass Green' },
  { hex: '#587246', ral: 6011, name: 'Reseda Green' },
  { hex: '#343E40', ral: 6012, name: 'Black Green' },
  { hex: '#6C7156', ral: 6013, name: 'Reed Green' },
  { hex: '#47402E', ral: 6014, name: 'Yellow Olive' },
  { hex: '#3B3C36', ral: 6015, name: 'Black Olive' },
  { hex: '#1E5945', ral: 6016, name: 'Turquoise Green' },
  { hex: '#4C9141', ral: 6017, name: 'May Green' },
  { hex: '#57A639', ral: 6018, name: 'Yellow Green' },
  { hex: '#BDECB6', ral: 6019, name: 'Pastel Green' },
  { hex: '#2E3A23', ral: 6020, name: 'Chrome Green' },
  { hex: '#89AC76', ral: 6021, name: 'Pale Green' },
  { hex: '#25221B', ral: 6022, name: 'Olive Grab' },
  { hex: '#308446', ral: 6024, name: 'Traffic Green' },
  { hex: '#3D642D', ral: 6025, name: 'Fern Green' },
  { hex: '#015D52', ral: 6026, name: 'Opal Green' },
  { hex: '#84C3BE', ral: 6027, name: 'Light Green' },
  { hex: '#2C5545', ral: 6028, name: 'Pine Green' },
  { hex: '#20603D', ral: 6029, name: 'Mint Green' },
  { hex: '#317F43', ral: 6032, name: 'Signal Green' },
  { hex: '#497E76', ral: 6033, name: 'Mint Turquoise' },
  { hex: '#7FB5B5', ral: 6034, name: 'Pastel Turquoise' },
  { hex: '#1C542D', ral: 6035, name: 'Pearl Green(Metalic Color)' },
  { hex: '#193737', ral: 6036, name: 'Pearl Opal Green(Metalic Color)' },
  { hex: '#008F39', ral: 6037, name: 'Pure Green' },
  { hex: '#00BB2D', ral: 6038, name: 'Luminous Green' },
  { hex: '#78858B', ral: 7000, name: 'Squirrel Grey' },
  { hex: '#8A9597', ral: 7001, name: 'Silver Grey' },
  { hex: '#7E7B52', ral: 7002, name: 'Olive Grey' },
  { hex: '#6C7059', ral: 7003, name: 'Moss Grey' },
  { hex: '#969992', ral: 7004, name: 'Signal Grey' },
  { hex: '#646B63', ral: 7005, name: 'Mouse Grey' },
  { hex: '#6D6552', ral: 7006, name: 'Beige Grey' },
  { hex: '#6A5F31', ral: 7008, name: 'Khaki Grey' },
  { hex: '#4D5645', ral: 7009, name: 'Green Grey' },
  { hex: '#4C514A', ral: 7010, name: 'Tarpaulin Grey' },
  { hex: '#434B4D', ral: 7011, name: 'Iron Grey' },
  { hex: '#4E5754', ral: 7012, name: 'Basalt Grey' },
  { hex: '#464531', ral: 7013, name: 'Brown Grey' },
  { hex: '#434750', ral: 7015, name: 'Slate Grey' },
  { hex: '#293133', ral: 7016, name: 'Anthracite Grey' },
  { hex: '#23282B', ral: 7021, name: 'Black Grey' },
  { hex: '#332F2C', ral: 7022, name: 'Umbra Grey' },
  { hex: '#686C5E', ral: 7023, name: 'Concrete Grey' },
  { hex: '#474A51', ral: 7024, name: 'Graphite Grey' },
  { hex: '#2F353B', ral: 7026, name: 'Granite Grey' },
  { hex: '#8B8C7A', ral: 7030, name: 'Stone Grey' },
  { hex: '#474B4E', ral: 7031, name: 'Blue Grey' },
  { hex: '#B8B799', ral: 7032, name: 'Pebble Grey' },
  { hex: '#7D8471', ral: 7033, name: 'Cement Grey' },
  { hex: '#8F8B66', ral: 7034, name: 'Yellow Grey' },
  { hex: '#D7D7D7', ral: 7035, name: 'Light Grey' },
  { hex: '#7F7679', ral: 7036, name: 'Platinum Grey' },
  { hex: '#7D7F7D', ral: 7037, name: 'Dusty Grey' },
  { hex: '#B5B8B1', ral: 7038, name: 'Agate Grey' },
  { hex: '#6C6960', ral: 7039, name: 'Quartz Grey' },
  { hex: '#9DA1AA', ral: 7040, name: 'Window Grey' },
  { hex: '#8D948D', ral: 7042, name: 'Traffic Grey A' },
  { hex: '#4E5452', ral: 7043, name: 'Traffic Grey B' },
  { hex: '#CAC4B0', ral: 7044, name: 'Silk Grey' },
  { hex: '#909090', ral: 7045, name: 'Telegrey 1' },
  { hex: '#82898F', ral: 7046, name: 'Telegrey 2' },
  { hex: '#D0D0D0', ral: 7047, name: 'Telegrey 4' },
  { hex: '#898176', ral: 7048, name: 'Pearl Mouse Grey(Metalic Color)' },
  { hex: '#826C34', ral: 8000, name: 'Green Brown' },
  { hex: '#955F20', ral: 8001, name: 'Ochre Brown' },
  { hex: '#6C3B2A', ral: 8002, name: 'Signal Brown' },
  { hex: '#734222', ral: 8003, name: 'Clay Brown' },
  { hex: '#8E402A', ral: 8004, name: 'Copper Brown' },
  { hex: '#59351F', ral: 8007, name: 'Fawn Brown' },
  { hex: '#6F4F28', ral: 8008, name: 'Olive Brown' },
  { hex: '#5B3A29', ral: 8011, name: 'Nut Brown' },
  { hex: '#592321', ral: 8012, name: 'Red Brown' },
  { hex: '#382C1E', ral: 8014, name: 'Sepia Brown' },
  { hex: '#633A34', ral: 8015, name: 'Chestnut Brown' },
  { hex: '#4C2F27', ral: 8016, name: 'Mahogany Brown' },
  { hex: '#45322E', ral: 8017, name: 'Chocolate Brown' },
  { hex: '#403A3A', ral: 8019, name: 'Grey Brown' },
  { hex: '#212121', ral: 8022, name: 'Black Brown' },
  { hex: '#A65E2E', ral: 8023, name: 'Orange Brown' },
  { hex: '#79553D', ral: 8024, name: 'Beige Brown' },
  { hex: '#755C48', ral: 8025, name: 'Pale Brown' },
  { hex: '#4E3B31', ral: 8028, name: 'Terra Brown' },
  { hex: '#763C28', ral: 8029, name: 'Pearl Copper(Metalic Color)' },
  { hex: '#FDF4E3', ral: 9001, name: 'Cream' },
  { hex: '#E7EBDA', ral: 9002, name: 'Grey White' },
  { hex: '#F4F4F4', ral: 9003, name: 'Signal White' },
  { hex: '#282828', ral: 9004, name: 'Signal Black' },
  { hex: '#0A0A0A', ral: 9005, name: 'Jet Black' },
  { hex: '#A5A5A5', ral: 9006, name: 'White Aluminium (Metalic Color)' },
  { hex: '#8F8F8F', ral: 9007, name: 'Grey Aluminium(Metalic Color)' },
  { hex: '#FFFFFF', ral: 9010, name: 'Pure White' },
  { hex: '#1C1C1C', ral: 9011, name: 'Graphite Black' },
  { hex: '#F6F6F6', ral: 9016, name: 'Traffic White' },
  { hex: '#1E1E1E', ral: 9017, name: 'Traffic Black' },
  { hex: '#D7D7D7', ral: 9018, name: 'Papyrus White' },
  { hex: '#9C9C9C', ral: 9022, name: 'Pearl Light Grey(Metalic Color)' },
  { hex: '#828282', ral: 9023, name: 'Pearl Dark Grey(Metalic Color)' },
]

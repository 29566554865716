import { useSelector } from 'react-redux'

import { selectNeedShowToast, selectToastMessage } from 'store/ui/selectors'

import styles from './styles.module.scss'


const Toast = () => {
  const needShowToast = useSelector(selectNeedShowToast)
  const toastMessage = useSelector(selectToastMessage)

  if (!needShowToast) return null

  return (
    <div className={styles.toast}>{toastMessage}</div>
  )
}

export default Toast

import { lazy, Suspense } from 'react'
import Modal from 'react-modal'
import { useSelector, useDispatch } from 'react-redux'

import { MODAL_TYPES } from 'constants/common'
import { FaIcon } from 'components/FaIcon'
import { selectIsModalOpen, selectTypeOpenedModal } from 'store/ui/selectors'
import { toggleOpenModal } from 'store/ui/slice'

import styles from './styles.module.scss'


Modal.setAppElement('#root')

const { VIEW, CSS, GET_PALETTE_FROM_IMAGE, UNDEFINED } = MODAL_TYPES

const MODAL_TYPE_MAP = {
  [VIEW]: lazy(() => import('containers/Modals/ModalView/ModalView')),
  [CSS]: lazy(() => import('containers/Modals/ModalCss/ModalCss')),
  [GET_PALETTE_FROM_IMAGE]: lazy(() => import('containers/Modals/ModalGetPalleteFromImage/ModalGetPalleteFromImage')),
  [UNDEFINED]: null,
} as const

const MODAL_TITLE_BY_TYPE = {
  [VIEW]: 'Просмотр цвета',
  [CSS]: 'CSS',
  [GET_PALETTE_FROM_IMAGE]: 'Создание палитры из изображения',
  [UNDEFINED]: '',
} as const

const Modals = () => {
  const dispatch = useDispatch()
  const isModalOpen = useSelector(selectIsModalOpen)
  const typeOpenedModal = useSelector(selectTypeOpenedModal)
  const Component = MODAL_TYPE_MAP[typeOpenedModal]
  const title = MODAL_TITLE_BY_TYPE[typeOpenedModal]

  if (!Component) return null

  const handleCloseModal = () => {
    dispatch(toggleOpenModal({ isOpen: false, typeModal: MODAL_TYPES.UNDEFINED }))
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleCloseModal}
      className={styles.modalWrapper}
      overlayClassName={styles.overlay}
    >
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <FaIcon
          iconWrapperClassName={styles.close}
          iconName="fa-xmark"
          onClick={handleCloseModal}
        />
      </div>
      <Suspense fallback="Loading...">
        <Component />
      </Suspense>
    </Modal>
  )
}

export default Modals

import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import { store } from 'store'
import App from 'containers/App/App'

import 'styles/common.css'


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)

import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import uniqBy from 'lodash/uniqBy'

import { getRandomColors, getMiddleColor, createColor, getDarkShade, getLightShade } from 'utils'
import { PaletteState, ChangeColorPayload, Color, Hex } from 'types'
import {
  DEFAULT_COUNT_COL,
  DEFAULT_BLACK_COLOR,
  DEFAULT_WHITE_COLOR,
  DEFAULT_BLACK_COLOR_STRING,
  DEFAULT_WHITE_COLOR_STRING,
} from 'constants/palette'


const colors = getRandomColors(DEFAULT_COUNT_COL, [])

const initialState: PaletteState = {
  history: [colors],
  currentIndexHistory: 0,
  countCol: DEFAULT_COUNT_COL,
  currentViewedColor: null,
}

export const paletteSlice = createSlice({
  name: 'palette',
  initialState,
  reducers: {
    changeColors: (state) => {
      const { countCol, currentIndexHistory, history } = state
      const currentColors = history[currentIndexHistory]
      const newColors = getRandomColors(countCol, currentColors)

      if (currentIndexHistory < history.length - 1) {
        history.splice(currentIndexHistory - 1)
      }

      history.push(newColors)
      state.currentIndexHistory = history.length - 1
    },
    changeColor: (state, action: PayloadAction<ChangeColorPayload>) => {
      const { payload: { color, index } } = action
      const { currentIndexHistory, history } = state
      const currentColors = history[currentIndexHistory]

      currentColors[index] = createColor(color)
    },
    changeIndexHistory: (state, action: PayloadAction<number>) => {
      state.currentIndexHistory = action.payload
      state.countCol = state.history[state.currentIndexHistory].length
    },
    setCurrentViewedColor: (state, action: PayloadAction<Color>) => {
      state.currentViewedColor = action.payload
    },
    insetrColor: (state, action: PayloadAction<number>) => {
      let newColor
      const { payload } = action
      const { history, currentIndexHistory } = state
      const currentColors = history[currentIndexHistory]
      const leftColor = currentColors[payload - 1]?.hex
      const rightColor = currentColors[payload]?.hex

      if (!leftColor) {
        newColor = createColor(getMiddleColor(DEFAULT_BLACK_COLOR_STRING, rightColor))
      } else if (!rightColor) {
        newColor = createColor(getMiddleColor(leftColor, DEFAULT_WHITE_COLOR_STRING))
      } else {
        newColor = createColor(getMiddleColor(leftColor, rightColor))
      }

      if (currentIndexHistory < history.length) {
        history.splice(currentIndexHistory + 1)
      }

      currentColors.splice(payload, 0, newColor)
      state.countCol += 1
    },
    toggleBlockColor: (state, action: PayloadAction<number>) => {
      const { payload } = action
      const { history, currentIndexHistory } = state
      const currentColors = history[currentIndexHistory]
      currentColors[payload].isBlocked = !currentColors[payload].isBlocked
    },
    resetBlockedColors: (state) => {
      const { history, currentIndexHistory } = state
      const currentColors = history[currentIndexHistory]
      history[currentIndexHistory] = currentColors.map((color) => ({ ...color, isBlocked: false }))
    },
    deleteSelectedColor: (state, action: PayloadAction<number>) => {
      const { payload } = action
      const { history, currentIndexHistory } = state
      const currentColors = history[currentIndexHistory]

      if (currentIndexHistory < history.length) {
        history.splice(currentIndexHistory + 1)
      }

      currentColors.splice(payload, 1)
      state.countCol = currentColors.length
    },
    generatingShades: (state, action: PayloadAction<number>) => {
      const { payload } = action
      const { history, currentIndexHistory } = state
      const currentColors = history[currentIndexHistory]
      const color = currentColors[payload]
      const { hex } = color
      const darkShade = Array
        .from({ length: 12 })
        .map((_, v) => createColor(getDarkShade(hex, v)))
      const lightShade = Array
        .from({ length: 12 })
        .map((_, v) => createColor(getLightShade(hex, v)))
        .reverse()

      const shades = uniqBy([
        DEFAULT_WHITE_COLOR,
        ...lightShade,
        color,
        ...darkShade,
        DEFAULT_BLACK_COLOR,
      ], 'hex')

      color.shades = shades
    },
    addNewPalletFromImage: (state, action: PayloadAction <Hex[]>) => {
      const { currentIndexHistory, history } = state
      const newPallete = action.payload.reduce((acc, hex) => {
        acc.push(createColor(hex))
        return acc
      }, [] as Color[])

      if (currentIndexHistory < history.length - 1) {
        history.splice(currentIndexHistory - 1)
      }

      history.push(newPallete)

      state.currentIndexHistory = history.length - 1
      state.countCol += 1
    },
  },
})

export const {
  changeColors,
  changeColor,
  changeIndexHistory,
  toggleBlockColor,
  resetBlockedColors,
  insetrColor,
  deleteSelectedColor,
  generatingShades,
  setCurrentViewedColor,
  addNewPalletFromImage,
} = paletteSlice.actions

export default paletteSlice.reducer

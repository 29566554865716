import { SyntheticEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { MODAL_TYPES } from 'constants/common'
import Rows from 'icons/Rows'
import { FaIcon } from 'components/FaIcon'
import { selectCurrentIndexHistory, selectHistory } from 'store/palette/selectors'
import { toggleBlockColor, deleteSelectedColor, setCurrentViewedColor } from 'store/palette/slice'
import { toggleOpenModal } from 'store/ui/slice'

import styles from './styles.module.scss'


type Props = {
  isBlocked: boolean,
  textColor: string,
  index: number,
  onGeneratePallete: (e: SyntheticEvent) => void,

}

export const ColorColActions = ({ isBlocked, textColor, index, onGeneratePallete }: Props) => {
  const dispatch = useDispatch()
  const history = useSelector(selectHistory)
  const historyIndex = useSelector(selectCurrentIndexHistory)
  const colors = history[historyIndex]

  const handleBlockColor = () => {
    dispatch(toggleBlockColor(index))
  }

  const handleDeleteColor = () => {
    dispatch(deleteSelectedColor(index))
  }

  const handleView = () => {
    dispatch(setCurrentViewedColor(colors[index]))
    dispatch(toggleOpenModal({ isOpen: true, typeModal: MODAL_TYPES.VIEW }))
  }

  const handleViewCss = () => {
    dispatch(setCurrentViewedColor(colors[index]))
    dispatch(toggleOpenModal({ isOpen: true, typeModal: MODAL_TYPES.CSS }))
  }

  return (
    <div className={classNames(styles.colorColActions, styles[textColor])}>
      <FaIcon iconName={isBlocked ? 'fa-lock' : 'fa-unlock'} onClick={handleBlockColor} />
      <FaIcon iconName="fa-xmark" onClick={handleDeleteColor} />
      <div className={styles.generatePallete} onClick={onGeneratePallete}>
        <Rows />
      </div>
      <FaIcon
        styleType="fa-regular"
        iconName="fa-eye"
        onClick={handleView}
        iconWrapperClassName={styles.view}
      />
      <FaIcon
        styleType="fa-brands"
        iconName="fa-css3-alt"
        onClick={handleViewCss}
        iconWrapperClassName={styles.view}
      />
    </div>
  )
}

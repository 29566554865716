import { Color } from 'types'


export const DEFAULT_COUNT_COL = 5 as const

export const DEFAULT_WHITE_COLOR_STRING: string = '#FFFFFF' as const

export const DEFAULT_BLACK_COLOR_STRING: string = '#000000' as const

export const DEFAULT_BLACK_COLOR: Color = {
  hex: DEFAULT_BLACK_COLOR_STRING,
  name: 'Black',
  textColor: 'white',
  isBlocked: false,
}

export const DEFAULT_WHITE_COLOR: Color = {
  hex: DEFAULT_WHITE_COLOR_STRING,
  name: 'White',
  textColor: 'black',
  isBlocked: false,
}

export const CSS_DATA_TITLE = {
  CSS_HEX: 'CSS_HEX',
  CSS_HSL: 'CSS_HSL',
  CSS_RGB: 'CSS_RGB',
  SCSS_HEX: 'SCSS_HEX',
  SCSS_HSL: 'SCSS_HSL',
  SCSS_RGB: 'SCSS_RGB',
  SCSS_GRADIENT: 'SCSS_GRADIENT',
} as const

export const CSS_DATA_COMMENT_BY_TITLE = {
  [CSS_DATA_TITLE.CSS_HEX]: '/* CSS HEX */',
  [CSS_DATA_TITLE.CSS_HSL]: '/* CSS HSL */',
  [CSS_DATA_TITLE.CSS_RGB]: '/* CSS RGB */',
  [CSS_DATA_TITLE.SCSS_HEX]: '/* SCSS HEX */',
  [CSS_DATA_TITLE.SCSS_HSL]: '/* SCSS HSL */',
  [CSS_DATA_TITLE.SCSS_RGB]: '/* SCSS RGB */',
  [CSS_DATA_TITLE.SCSS_GRADIENT]: '/* SCSS Gradient */',
} as const

export const GRADIENT_POSITION = {
  TOP: 'top',
  RIGHT: 'right',
  BOTTOM: 'bottom',
  LEFT: 'left',
  TOP_RIGHT: 'top-right',
  BOTTOM_RIGHT: 'bottom-right',
  TOP_LEFT: 'top-left',
  BOTTOM_LEFT: 'bottom-left',
  RADIAL: 'radial',
} as const

export const GRADIENT_TYPES = {
  LINEAR: 'linear-gradient',
  RADIAL: 'radial-gradient',
} as const

export const GRADIENT_TYPE_BY_POSITION = {
  [GRADIENT_POSITION.TOP]: GRADIENT_TYPES.LINEAR,
  [GRADIENT_POSITION.RIGHT]: GRADIENT_TYPES.LINEAR,
  [GRADIENT_POSITION.BOTTOM]: GRADIENT_TYPES.LINEAR,
  [GRADIENT_POSITION.LEFT]: GRADIENT_TYPES.LINEAR,
  [GRADIENT_POSITION.TOP_RIGHT]: GRADIENT_TYPES.LINEAR,
  [GRADIENT_POSITION.BOTTOM_RIGHT]: GRADIENT_TYPES.LINEAR,
  [GRADIENT_POSITION.TOP_LEFT]: GRADIENT_TYPES.LINEAR,
  [GRADIENT_POSITION.BOTTOM_LEFT]: GRADIENT_TYPES.LINEAR,
  [GRADIENT_POSITION.RADIAL]: GRADIENT_TYPES.RADIAL,
} as const

export const GRADIENT_DEG_BY_TYPE = {
  [GRADIENT_POSITION.TOP]: '0deg',
  [GRADIENT_POSITION.RIGHT]: '90deg',
  [GRADIENT_POSITION.BOTTOM]: '180deg',
  [GRADIENT_POSITION.LEFT]: '270deg',
  [GRADIENT_POSITION.TOP_RIGHT]: '45deg',
  [GRADIENT_POSITION.BOTTOM_RIGHT]: '135deg',
  [GRADIENT_POSITION.TOP_LEFT]: '225deg',
  [GRADIENT_POSITION.BOTTOM_LEFT]: '315deg',
  [GRADIENT_POSITION.RADIAL]: '',
} as const

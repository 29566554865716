import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store'


export const selectPalette = ({ palette }: RootState) => palette

export const selectHistory = createSelector(selectPalette, ({ history }) => history)

export const selectCurrentIndexHistory = createSelector(selectPalette, ({ currentIndexHistory }) => currentIndexHistory)

export const selectCountCol = createSelector(selectPalette, ({ countCol }) => countCol)

export const selectCurrentViewedColor = createSelector(selectPalette, ({ currentViewedColor }) => currentViewedColor)

import chroma from 'chroma-js'
import type { Color as ChromaColor } from 'chroma-js'
import ntc from 'ntcjs'

import {
  Color,
  StyleTypes,
  ColorTypes,
  GradientTypes,
  Ral,
  ReduceAccRal,
  SccData,
  CssDataValue,
  VariableNames,
} from 'types'
import { RALS } from 'constants/ral'
import { CSS_DATA_TITLE, GRADIENT_POSITION, GRADIENT_TYPE_BY_POSITION, GRADIENT_DEG_BY_TYPE } from 'constants/palette'


export const getTextColor = (color: string): string => {
  const luminance = chroma(color).luminance()
  return luminance > 0.5 ? 'black' : 'white'
}

export const createColor = (hex: string, isBlocked = false): Color => {
  const nameMatch = ntc.name(hex)
  const name = nameMatch[1]
  const textColor = getTextColor(hex)

  return { hex, name, textColor, isBlocked }
}

export const getRandomColors = (colCount: number, prevColors: Color[]): Color[] => {
  const palette: Color[] = []

  for (let i = 0; i < colCount; i += 1) {
    const { isBlocked, hex: prevHex } = prevColors[i] || {}
    const color = chroma.random()
    const hex = isBlocked ? prevHex : color.hex()
    const newColor = createColor(hex, isBlocked)

    palette.push(newColor)
  }

  return palette
}

export const getMiddleColor = (firstColor: string, secondColor: string): string => {
  const color = chroma.mix(firstColor, secondColor, 0.5, 'rgb')
  return color.hex()
}

export const getDarkShade = (hex: string, i:number): string => {
  const rgb = chroma(hex).rgb()
  const offset = +(1 / 12).toFixed(3)
  const newRgb = [
    Math.round(rgb[0] * (1 - offset * i)),
    Math.round(rgb[1] * (1 - offset * i)),
    Math.round(rgb[2] * (1 - offset * i)),
  ]

  return chroma(newRgb).hex()
}

export const getLightShade = (hex: string, i:number): string => {
  const rgb = chroma(hex).rgb()
  const offset = +(1 / 12).toFixed(3)
  const newRgb = [
    Math.round(rgb[0] + (255 - rgb[0]) * i * offset),
    Math.round(rgb[1] + (255 - rgb[1]) * i * offset),
    Math.round(rgb[2] + (255 - rgb[2]) * i * offset),
  ]

  return chroma(newRgb).hex()
}

// 100 it is max delta between #000000 and #FFFFFF
export const getHEXtoRAL = (hex: string): Ral => {
  const result = RALS.reduce((acc: ReduceAccRal, item: Ral): ReduceAccRal => {
    if (acc.delta === 0) return acc

    const deltaE = chroma.deltaE(hex, item.hex)

    return deltaE < acc.delta ? { delta: deltaE, ral: item } : acc
  }, { delta: 100, ral: {} as Ral })

  return result.ral
}

export const rgbToHex = (rgb: number[]) => rgb.map((item) => item.toString(16).padStart(2, '0')).join('')

export const getHexFormRgba = (rgb: number[]) => chroma(rgb).hex()

export const getHslOrRgbStringCss = (color: ChromaColor, colorType: string) => (
  color.css(colorType === 'hsl' ? colorType : undefined)
)

export const getViewedColorData = (viewedColor: Color | null) => {
  if (!viewedColor) return []

  const { hex, name } = viewedColor
  const color = chroma(hex)
  const hsv: number[] = color.hsv().map((item) => +item.toFixed(2))
  const hsl: number[] = color.hsl().map((item) => +item.toFixed(2))
  const rgb: number[] = color.rgb()
  const rgba: number[] = color.rgba()
  const cmyk: number[] = color.cmyk().map((item) => +item.toFixed(2))
  const lab: number[] = color.lab().map((item) => +item.toFixed(2))
  const ral = getHEXtoRAL(hex)

  return [
    { title: 'Название цвета', value: name },
    { title: 'HEX', value: hex.replace('#', '') },
    { title: 'HSV', value: hsv.join(', ') },
    { title: 'HSL', value: hsl.join(', ') },
    { title: 'RGB', value: rgb.join(', ') },
    { title: 'RGBA', value: rgba.join(', ') },
    { title: 'CMYK', value: cmyk.join(', ') },
    { title: 'LAB', value: lab.join(', ') },
    { title: 'RAL', value: `~ ${ral.ral}` },
    { title: 'RAL Name', value: ral.name },
  ]
}

export const stringToKebabCase = (value: string) => {
  const stringArray = value.split(' ')
  return stringArray.join('-')
}

export const getGradientString = (colors: Color[]) => {
  const hexsString = colors.map(((color: Color) => color.hex)).join(', ')
  const gradients = Object.values(GRADIENT_POSITION).reduce((acc, gradient: GradientTypes) => {
    const type = GRADIENT_TYPE_BY_POSITION[gradient]
    const deg = GRADIENT_DEG_BY_TYPE[gradient]
    const degPart = deg ? `${deg}, ` : ''

    acc.push({
      variableName: `$gradient-${gradient}: `,
      variableValue: `(${degPart}${hexsString});`,
      gradientType: `${type}`,
    })

    return acc
  }, [] as CssDataValue[])

  return gradients
}

export const getCssData = (colors: Color[] = []) => {
  const cssData = {} as SccData
  const variableNames = {} as VariableNames

  colors.forEach((itemColor: Color) => {
    const { hex, name } = itemColor
    const color = chroma(hex)
    const nameKebabCase = stringToKebabCase(name.toLocaleLowerCase())

    Object.values(CSS_DATA_TITLE).forEach((item) => {
      const itemSplit = item.split('_')
      const styleType = itemSplit[0] as StyleTypes
      const colorType = itemSplit[1].toLowerCase() as ColorTypes
      const isGradient = !color[colorType]
      let count = 0

      if (isGradient) return

      const colorString = colorType === 'hex' ? `${hex}` : getHslOrRgbStringCss(color, colorType)
      const prefix = styleType === 'CSS' ? '--' : '$'
      const variableName = `${prefix}${nameKebabCase}`

      if (!cssData[item]) {
        cssData[item] = []
      }

      if (!variableNames[item]) {
        variableNames[item] = []
      }

      if (variableNames[item].includes(variableName)) {
        const duplicateVariableName = variableNames[item].filter((i) => i === variableName)
        count = duplicateVariableName.length
      }

      variableNames[item].push(variableName)

      cssData[item].push({
        variableName: `${variableName}${count > 0 ? `-${count + 1}` : ''}: `,
        variableValue: `${colorString};`,
      })
    })
  })

  if (colors.length > 1) {
    cssData[CSS_DATA_TITLE.SCSS_GRADIENT] = getGradientString(colors)
  }

  return cssData
}

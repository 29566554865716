import { MOBILE_WIDTH } from 'constants/common'
import useWindowSize from 'hooks/useWindowSize'
import Header from 'containers/Header/Header'
import Footer from 'containers/Footer/Footer'
import Palette from 'containers/Palette/Palette'
import Modal from 'containers/Modals/Modal'
import Toast from 'components/Toast/Toast'


const App = () => {
  const { width } = useWindowSize()
  const isMobile = width <= MOBILE_WIDTH

  return (
    <div className="App">
      <Header />
      <Palette />
      {isMobile && <Footer />}
      <Modal />
      <Toast />
    </div>
  )
}

export default App

import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store'


export const selectUi = ({ ui }: RootState) => ui

export const selectNeedShowToast = createSelector(selectUi, ({ needShowToast }) => needShowToast)

export const selectIsModalOpen = createSelector(selectUi, ({ isModalOpen }) => isModalOpen)

export const selectTypeOpenedModal = createSelector(selectUi, ({ typeOpenedModal }) => typeOpenedModal)

export const selectToastMessage = createSelector(selectUi, ({ toastMessage }) => toastMessage)

import { useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { ColorCol } from 'containers/ColorCol'
import { selectCurrentIndexHistory, selectHistory } from 'store/palette/selectors'
import { changeColors, changeIndexHistory } from 'store/palette/slice'

import styles from './styles.module.scss'


const Palette = () => {
  const dispatch = useDispatch()
  const history = useSelector(selectHistory)
  const historyIndex = useSelector(selectCurrentIndexHistory)
  const [indexOpenedShade, setIndexOpenedShade] = useState<null | number>(null)
  const historyLength = history.length - 1
  const colors = history[historyIndex]

  const handleClickCol = () => {
    if (indexOpenedShade !== null) {
      setIndexOpenedShade(null)
    }
  }

  const handleSetOpenedShade = (index: number) => {
    setIndexOpenedShade(index)
  }

  const handleKeyPress = useCallback((e: KeyboardEvent) => {
    e.preventDefault()
    e.stopPropagation()

    const { ctrlKey, code } = e

    switch (true) {
      case code === 'Space':
        dispatch(changeColors())
        break
      case ctrlKey && code === 'KeyZ' && historyIndex > 0: {
        dispatch(changeIndexHistory(historyIndex - 1))
        break
      }
      case ctrlKey && code === 'KeyY' && historyIndex < historyLength:
        dispatch(changeIndexHistory(historyIndex + 1))
        break
      default:
        break
    }
  }, [dispatch, historyIndex, historyLength])

  useEffect(() => {
    document.addEventListener('keyup', handleKeyPress)
    return () => document.removeEventListener('keyup', handleKeyPress)
  }, [handleKeyPress])

  return (
    <div className={styles.palette}>
      {Array.from({ length: colors.length }, (_, k) => k).map((i) => (
        <ColorCol
          key={i}
          color={colors[i]}
          index={i}
          isShadeOpen={i === indexOpenedShade}
          onClickCol={handleClickCol}
          onSetOpenedShade={handleSetOpenedShade}
        />
      ))}
    </div>
  )
}

export default Palette

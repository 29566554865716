import { useState, useLayoutEffect } from 'react'


type SizeUseState = {
  width: number,
  height: number,
}

const useWindowSize = () => {
  const [size, setSize] = useState<SizeUseState>({ width: window.innerWidth, height: window.innerHeight })

  const handleResize = () => {
    const { innerWidth, innerHeight } = window
    setSize({ width: innerWidth, height: innerHeight })
  }

  useLayoutEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return size
}

export default useWindowSize

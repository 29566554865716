import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'

import { MODAL_TYPES } from 'constants/common'
import Logo from 'icons/Logo'
import Reset from 'icons/Reset'
import Undo from 'icons/Undo'
import Redo from 'icons/Redo'
import { FaIcon } from 'components/FaIcon'
import { selectCurrentIndexHistory, selectHistory } from 'store/palette/selectors'
import { changeIndexHistory, resetBlockedColors } from 'store/palette/slice'
import { toggleOpenModal } from 'store/ui/slice'

import styles from './styles.module.scss'


const Header = () => {
  const dispatch = useDispatch()
  const currentIndexHistory = useSelector(selectCurrentIndexHistory)
  const history = useSelector(selectHistory)
  const historyLength = history.length - 1
  const isFirstElement = currentIndexHistory === 0
  const isLastElement = historyLength === currentIndexHistory

  const handleUndo = () => {
    if (currentIndexHistory !== 0) {
      dispatch(changeIndexHistory(currentIndexHistory - 1))
    }
  }

  const handleRedo = () => {
    if (currentIndexHistory < historyLength) {
      dispatch(changeIndexHistory(currentIndexHistory + 1))
    }
  }

  const handleReset = () => {
    dispatch(resetBlockedColors())
  }

  const handleOpenImageModal = () => {
    dispatch(toggleOpenModal({ isOpen: true, typeModal: MODAL_TYPES.GET_PALETTE_FROM_IMAGE }))
  }

  return (
    <div className={styles.header}>
      <div className={styles.left}>
        <Logo />
      </div>
      <div className={styles.right}>
        <FaIcon
          iconWrapperClassName={styles.icon}
          styleType="fa-regular"
          iconName="fa-image"
          onClick={handleOpenImageModal}
        />
        <div className={styles.reset} onClick={handleReset}>
          <Reset />
        </div>
        <div className={styles.undoRedo}>
          <div className={classNames(isFirstElement && styles.disabled)} onClick={handleUndo}>
            <Undo />
          </div>
          <div className={classNames(isLastElement && styles.disabled)} onClick={handleRedo}>
            <Redo />
          </div>
        </div>
        {/* <FaIcon
          iconWrapperClassName={styles.info}
          iconName="fa-circle-info"
        /> */}
      </div>
    </div>
  )
}

export default Header

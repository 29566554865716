/* eslint-disable max-len */
export default () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.33335 12V18.6667H12M26.6667 21.3333C26.0034 15.3333 22.1768 10.6667 16 10.6667C12.3611 10.6667 8.09522 13.6908 6.27455 18"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>

)

/* eslint-disable max-len */
export default () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.6666 12V18.6667H20M5.33331 21.3333C5.99657 15.3333 9.82325 10.6667 16 10.6667C19.6389 10.6667 23.9048 13.6908 25.7254 18"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

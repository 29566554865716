import { SyntheticEvent } from 'react'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { ValueOf } from 'types'
import { insetrColor } from 'store/palette/slice'

import styles from './styles.module.scss'


type Props = {
  index: number;
  countCol: number;
  isMobile: boolean;
}

const POSITIONS = {
  LEFT: 'left',
  RIGHT: 'right',
} as const

type PositionType = ValueOf<typeof POSITIONS>

export const GeneratorMiddleColor = ({ index, countCol, isMobile }: Props) => {
  const dispatch = useDispatch()

  const handleGenerateMiddleColor = (e: SyntheticEvent<HTMLElement>) => {
    const { position } = e.currentTarget.dataset
    const positionInsert = position === POSITIONS.LEFT ? index : index + 1

    switch (true) {
      case index === 0 && position === POSITIONS.LEFT:
        dispatch(insetrColor(index))
        return
      case index + 1 === countCol && position === POSITIONS.RIGHT:
        dispatch(insetrColor(index + 1))
        return
      default:
        dispatch(insetrColor(positionInsert))
    }
  }

  const renderContent = (position: PositionType) => {
    const isFirst = index === 0 && position === POSITIONS.LEFT
    const isLast = index + 1 === countCol && position === POSITIONS.RIGHT

    return (
      <div
        data-position={position}
        className={classNames(styles.generateColorPlus, isFirst && styles.first, isLast && styles.last)}
        onClick={handleGenerateMiddleColor}
      >
        <i className="fa-solid fa-plus" />
      </div>
    )
  }

  if (countCol > 11 || isMobile) return null

  return (
    <>
      <div className={classNames(styles.generateColor, styles.left)}>
        {renderContent(POSITIONS.LEFT)}
      </div>
      <div className={classNames(styles.generateColor, styles.right)}>
        {renderContent(POSITIONS.RIGHT)}
      </div>
    </>
  )
}

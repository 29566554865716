import { useRef, useState, SyntheticEvent, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import copy from 'copy-to-clipboard'

import { Color } from 'types'
import { MOBILE_WIDTH } from 'constants/common'
import useWindowSize from 'hooks/useWindowSize'
import { GeneratorMiddleColor } from 'containers/GeneratorMiddleColor'
import { Shades } from 'containers/Shades'
import { ColorColActions } from 'containers/ColorColActions'
import { selectCurrentIndexHistory, selectHistory, selectCountCol } from 'store/palette/selectors'
import { generatingShades } from 'store/palette/slice'
import { showToast, hideToast } from 'store/ui/slice'

import styles from './styles.module.scss'


type Props = {
  color: Color,
  index: number,
  isShadeOpen: boolean,
  onClickCol: () => void,
  onSetOpenedShade: (index: number) => void,
}

export const ColorCol = ({ color, index, isShadeOpen, onClickCol, onSetOpenedShade }: Props) => {
  const dispatch = useDispatch()
  const history = useSelector(selectHistory)
  const historyIndex = useSelector(selectCurrentIndexHistory)
  const countCol = useSelector(selectCountCol)
  const colRef = useRef<HTMLDivElement>(null)
  const infoRef = useRef<HTMLDivElement>(null)
  const [transform, setTransform] = useState('scale(1)')
  const windowSize = useWindowSize()
  const colors = history[historyIndex]
  const isMobile = windowSize.width <= MOBILE_WIDTH
  const { hex, name, textColor, shades, isBlocked } = color
  const { current: colRefCurrent } = colRef
  const { current: infoRefCurrent } = infoRef

  const copyColorToClipboard = () => {
    copy(hex)
    dispatch(showToast())
    setTimeout(() => dispatch(hideToast()), 2000)
  }

  const getStyle = () => isMobile
    ? { backgroundColor: hex, height: `${100 / countCol}%` }
    : { backgroundColor: hex, width: `${100 / countCol}%` }

  const getScaleForInfo = useCallback(() => {
    let scale = 1

    if (colRef.current && infoRef.current && !isMobile) {
      const infoCoord = infoRef.current.getBoundingClientRect()
      scale = (window.innerWidth / countCol) / (infoCoord.width + 20)
    }

    setTransform(`scale(${scale > 1 ? 1 : scale})`)
  }, [countCol, isMobile])

  const handleClickCol = () => {
    onClickCol()
  }

  const handleGeneratePalette = (e: SyntheticEvent) => {
    e.stopPropagation()

    if (!colors[index].shades?.length) {
      dispatch(generatingShades(index))
    }

    onSetOpenedShade(index)
  }

  useEffect(() => {
    getScaleForInfo()
  }, [colRefCurrent, infoRefCurrent, getScaleForInfo])

  return (
    <div
      ref={colRef}
      className={classNames(styles.colorCol, styles[textColor])}
      style={getStyle()}
      onClick={handleClickCol}
    >
      <div ref={infoRef} className={styles.info}>
        <div
          className={styles.hex}
          style={{ transform }}
          onClick={copyColorToClipboard}
        >
          {hex}
        </div>
        <div className={styles.colorName}>{name}</div>
      </div>
      <ColorColActions
        index={index}
        isBlocked={isBlocked}
        textColor={textColor}
        onGeneratePallete={handleGeneratePalette}
      />
      <Shades
        shades={shades}
        activeColor={hex}
        index={index}
        isOpen={isShadeOpen}
      />
      <GeneratorMiddleColor index={index} countCol={countCol} isMobile={isMobile} />
    </div>
  )
}

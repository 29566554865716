import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { UiState, ToggleModalAction } from 'types'
import { MODAL_TYPES } from 'constants/common'


const initialState: UiState = {
  needShowToast: false,
  isModalOpen: false,
  typeOpenedModal: MODAL_TYPES.UNDEFINED,
  toastMessage: '',
}

const DEFAULT_TOAST_MESSAGE = 'Цвет скопирован в буфер!'

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<string | undefined>) => {
      state.needShowToast = true
      state.toastMessage = action.payload ?? DEFAULT_TOAST_MESSAGE
    },
    hideToast: (state) => {
      state.needShowToast = false
      state.toastMessage = ''
    },
    toggleOpenModal: (state, action: PayloadAction<ToggleModalAction>) => {
      const { payload: { isOpen, typeModal } } = action
      state.isModalOpen = isOpen
      state.typeOpenedModal = typeModal
    },
  },
})

export const { showToast, hideToast, toggleOpenModal } = uiSlice.actions

export default uiSlice.reducer

import classNames from 'classnames'


type StyleType = 'fa-solid' | 'fa-regular' | 'fa-light' | 'fa-duotone' | 'fa-thin' | 'fa-brands'

type Props = {
  iconWrapperClassName?: string;
  styleType?: StyleType;
  iconName: string;
  onClick?: () => void;
}

export const FaIcon = ({ iconWrapperClassName, onClick, styleType = 'fa-solid', iconName }: Props) => (
  <div className={iconWrapperClassName} onClick={onClick}>
    <i className={classNames(styleType, iconName)} />
  </div>
)
